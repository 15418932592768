export default [
  {
    date: "2025-04-02",
    color: "error",
    icon: "mdi-cash-multiple",
    title: "Rechnungen",
    text: "Bei der Rechnungsstellung gibt es neue Sortier- und Filterfunktionen für Personen.",
    teachersOnly: true,
  },
  {
    date: "2025-04-01",
    color: "error",
    icon: "mdi-account-cash",
    title: "Finanzen",
    text: "Die Kontenansichten können nun nach Anlass gruppiert werden.",
    teachersOnly: false,
  },
  {
    date: "2024-12-13",
    color: "grey",
    icon: "mdi-calendar",
    title: "Kalender-Abos",
    text: "LK welche die Termine bereits abonniert haben, sollten die URL anpassen! Neu braucht es ein «Geheimnis» – sonst erhält man nur noch die öffentlichen Termine!",
    teachersOnly: true,
  },
  {
    date: "2024-12-13",
    color: "grey",
    icon: "mdi-calendar",
    title: "Kalender-Abos",
    text: "Neben den Terminen können neu Unterrichts-Lektionen, Proben und der Ferienplan als ICal-Abo in der Kalender-App (Outlook, Apple Kalender, Google, ...) hinzugefügt werden. Zu finden unter «weiteres».",
    teachersOnly: false,
  },
  {
    date: "2024-12-02",
    color: "info",
    icon: "mdi-bed",
    title: "Absenzen-Heatmap",
    text: "Klassenlehrkräfte können neu wählen, ob sie die durch Halbtage verursachten Absenzen in der Heatmap mitgezählt haben möchten oder nicht.",
    teachersOnly: true,
  },
  {
    date: "2024-10-21",
    color: "success",
    icon: "mdi-check",
    title: "Absenzenkontrolle",
    text: "Den LK steht unten links in der Absenzenkontrolle neu ein E-Mail-Button zur Verfügung worüber man schnell alle abwesenden Schüler:innen erreicht.",
    teachersOnly: true,
  },
  {
    date: "2024-10-21",
    color: "success",
    icon: "mdi-dice-multiple",
    title: "Kurs-Würfel",
    text: "Neu können LK beim Erzeugen von zufälligen Gruppen eines Kurses die Geschlechter explizit mischen lassen.",
    teachersOnly: true,
  },
  {
    date: "2024-09-05",
    color: "grey",
    icon: "mdi-cogs",
    title: "Einstellungen und Icons",
    text: "Neben Terminen können nun auch Absenzen ausgeblendet werden. Zudem kann unter «Einstellungen» festgelegt werden, ob man die Icons lieber nicht anzeigen möchte.",
  },
  {
    date: "2024-08-01",
    color: "error",
    icon: "mdi-school",
    title: "Klassen, Kurse, Personen",
    text: "Die Ansichten Klassen, Kurse und Personen sind überarbeitet worden.",
  },
  {
    date: "2024-06-12",
    color: "error",
    icon: "mdi-cash",
    title: "Mein Druckkonto und Mein Konto Schüler:innen",
    text: "Sind jetzt unter Finanzen zu finden.",
  },
  {
    date: "2024-04-19",
    color: "info",
    icon: "mdi-bed",
    title: "Absenzenwesen",
    text: "Das Absenzenwesen ist überarbeitet worden.",
  },
  {
    date: "2023-09-26",
    color: "info",
    icon: "mdi-octagram",
    title: "Probenplan Schüler:innen",
    text: "Der Probenplan steht nun in der neuen Kalender- und Listenansicht zur Verfügung.",
  },
  {
    date: "2023-09-04",
    color: "error",
    icon: "mdi-account-details",
    title: "Fachschaften",
    text: "Lehrkräfte und Angestellte finden im Abschnitt «Informationen» eine neue Seite «Fachschaften»: Hier sieht man, wer in welcher FS ist – inkl. Link zur Verfügbarkeit.",
    teachersOnly: true,
  },
  {
    date: "2023-07-12",
    color: "info",
    icon: "mdi-account-group",
    title: "meine Klasse",
    text: "Dort finden Klassenlehrkräfte neu die Schulkonto-Übersicht «Finanzen», sowie die Schäftli-Zuweisung ihrer Klasse(n).",
  },
  {
    date: "2023-07-11",
    color: "grey",
    icon: "mdi-cogs",
    title: "Einstellungen",
    text: "In den Einstellungen unter Weiteres kannst du nun deinen bevorzugten E-Mail-Client auswählen.",
  },
  {
    date: "2023-07-11",
    color: "info",
    icon: "mdi-account-remove",
    title: "Dispensationen",
    text: "Es gibt neue Ansichten der Dispensationen: Schüler:innen finden ihre Dispensationen unter «Unterricht». Klassenlehrpersonen können Dispensationen der Klasse unter «meine Klasse» anschauen und bearbeiten.",
  },
  {
    date: "2023-07-08",
    color: "info",
    icon: "mdi-file-table",
    title: "Kleine Verbesserungen",
    text: "Die Excel-Notenliste ist überarbeitet worden, die Schüler*innen können nun beliebig sortiert werden. Im Klassenbuch werden SOK-Delegierte mit einem Icon markiert.",
    teachersOnly: true,
  },
  {
    date: "2023-07-01",
    color: "info",
    icon: "mdi-account-group",
    title: "Menübereich «meine Klasse»",
    text: "Für Klassenlehrpersonen gibt es neu den Menübereich «meine Klasse», wo sie schnell Informationen zu der eigenen Klasse (z.B. Absenzenwesen, Dispensationen, myGymer-Wahl) finden und bearbeiten können.",
    teachersOnly: true,
  },
  {
    date: "2023-04-14",
    color: "info",
    icon: "mdi-printer",
    title: "Druckkonto Schüler*innen",
    text: "Neues Design für die Druckkosten-Ansicht von Schüler*innen.<br>Die Darstellung erfolgt nun in einer Timeline.",
  },
  {
    date: "2023-04-12",
    color: "info",
    icon: "mdi-check",
    title: "Anwesenheitskontrolle",
    text: "Neues Design für die Anwesenheitskontrolle.<br>Die LK können bei Sonderveranstaltungen mehrere Schüler*innen aufs Mal melden.",
    teachersOnly: true,
  },
  {
    date: "2023-03-06",
    color: "success",
    icon: "mdi-star",
    title: "myGymer-Angebote",
    text: "Neues Design für die Anmeldungen der myGymer-Angebote.<br>Neu werden Fächer-Schienen berücksichtigt.",
  },
  {
    date: "2022-09-06",
    color: "success",
    icon: "mdi-puzzle",
    title: "Maturaarbeiten",
    text: "Neues Design für die Maturaarbeiten.<br>Die Funktionalität bleibt wie gehabt.",
  },
  {
    date: "2022-05-24",
    color: "error",
    icon: "mdi-file",
    title: "Dokumente",
    text: "Neues Design für die Dokumentenseite.<br>Die Kategorien werden nun in einer Baumansicht dargestellt.",
  },
  {
    date: "2022-03-30",
    color: "success",
    icon: "mdi-calendar",
    title: "Termine",
    text: "Neues Design für die Terminliste.<br>Neu können die Termine auch in einer Kalenderansicht dargestellt werden.",
  },
];
